import React from "react";
import './Experience.css'

const Experience = () => {
    return (
        <div className="experience" style={{color:'black'}}>
     
        <div className="achievement">
        <div className="circle">10+</div>
        <span>Completed</span>
        <span>Projects</span>
        
        </div>

        <div className="achievement">
            <div className="circle">100%</div>
            <span>Score</span>
            <span>Robocon'22</span>
            

        </div>

        <div className="achievement">
            <div className="circle">7048</div>
            <span>AIR</span>
            <span>JEE-Advanced</span>
            

        </div>
        




        </div>
        



    )
}



export default Experience;